import { recipeTypes } from '@ducks/recipe';
import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECIPE_TAGS_PENDING: {
      return { ...state, getStatus: requestStatus.pending };
    }
    case types.GET_RECIPE_TAGS_FULFILLED: {
      return {
        ...state,
        getStatus: requestStatus.fulfilled,
        areTagsSelected: false,
        tags: action.response
      };
    }
    case types.GET_RECIPE_TAGS_REJECTED: {
      return { ...state, getStatus: requestStatus.rejected };
    }
    case types.TOGGLE_SYSTEM_RECIPE: {
      return { ...state, systemRecipe: !state.systemRecipe };
    }
    case types.SELECT_RECIPE_TAGS: {
      return {
        ...state,
        areTagsSelected: true,
        tags: Object.keys(state.tags).map(key => ({
          ...state.tags[key],
          tags: state.tags[key].tags.map(tag => {
            const { id: tagId } = tag;
            const { tags: actionTags = [] } = action;
            const isSelected = actionTags.map(t => t.id).includes(tagId);
            return {
              ...tag,
              selected: isSelected
            };
          })
        }))
      };
    }
    case types.TOGGLE_RECIPE_TAGS: {
      const {
        tag: {
          id: actionTagId,
          parentId: actionTagParentTagId,
          selected: actionTagWasSelected
        } = {}
      } = action;

      return {
        ...state,
        tags: Object.keys(state.tags).map(key => ({
          ...state.tags[key],
          tags: state.tags[key].tags.map(tag => {
            const {
              id: tagId,
              selected: tagIsSelected,
              parentId: tagParentId
            } = tag;
            const isSameTag = tagId === actionTagId;
            const isParentTag = tagId === actionTagParentTagId;
            const isUpperLevelParentTag = state.tags[key].tags.find(
              ({ parentId, id }) => {
                const hasParent = state.tags[key].tags.some(
                  ({ parentId: parentId2, id: id2 }) =>
                    id === parentId2 && id2 === actionTagParentTagId
                );
                return (
                  parentId === tagId &&
                  (id === actionTagParentTagId || hasParent)
                );
              }
            );
            const isLowerLevelChildTag = state.tags[key].tags.find(
              ({ parentId, id }) => {
                const hasChild = state.tags[key].tags.some(
                  ({ parentId: parentId2, id: id2 }) =>
                    id2 === parentId && parentId2 === actionTagId
                );
                return (
                  id === tagParentId && (parentId === actionTagId || hasChild)
                );
              }
            );

            const isChildTag = tagParentId === actionTagId;
            const isChildTagToBeDeSelected =
              (isLowerLevelChildTag || isChildTag) &&
              tagIsSelected &&
              actionTagWasSelected;

            const isSecondLevelParentTagToBeSelected =
              (isUpperLevelParentTag || isParentTag) &&
              !tagIsSelected &&
              !actionTagWasSelected;

            return isSameTag ||
              isSecondLevelParentTagToBeSelected ||
              isChildTagToBeDeSelected
              ? {
                  ...tag,
                  selected: !actionTagWasSelected,
                  type: state.tags[key].tagType
                }
              : tag;
          })
        }))
      };
    }
    case recipeTypes.RESET_RECIPE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default tagsReducer;
